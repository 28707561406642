import React from 'react';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import memoizeOne from 'memoize-one';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import type { RouterActionReplace as Replace } from '@atlassian/jira-router';
import AgentViewDI from '@atlassian/jira-servicedesk-queues-agent-view/src/view/index.tsx';
import NoQueuesViewDI from '@atlassian/jira-servicedesk-queues-no-queues-view/src/ui/index.tsx';
import type { PageProps } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/types.tsx';
import { onErrorHandler as onErrorCallback } from '@atlassian/jira-spa-apps-queues-app-base/src/utils/error-callback';

type Props = {
	NoQueuesView: typeof NoQueuesViewDI;
	AgentView: typeof AgentViewDI;
	pageContext: PageProps;
	replace: Replace;
};

const deprecatedAgentViewProps = {
	appRef: noop,
	onAtLeastOneIssueLoaded: noop,
	onDeleteComplete: noop,
	onQueueIssueCountUpdated: noop,
	onProjectStateHashUpdate: noop,
};

const getInitialState = memoizeOne((appProps) => ({ appProps }));

const getErrorCallback = memoizeOne((projectKey) =>
	memoizeOne(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(replace: Replace) => (e: any) =>
			onErrorCallback(e, () => replace(`/projects/${projectKey}/queues`)),
	),
);

const DefaultQueues = (props: Props) => {
	const { NoQueuesView, AgentView, pageContext, replace } = props;
	return (
		<NoQueuesView
			{...deprecatedAgentViewProps}
			AgentView={AgentView}
			// @ts-expect-error - TS2322 - Type '{ AgentView: ComponentType<Props>; onQueueRenderFailure: (e: any) => void; initialState: { appProps: any; }; locale: Locale; isQueueVisible: true; appRef: (...args: any[]) => void; onAtLeastOneIssueLoaded: (...args: any[]) => void; onDeleteComplete: (...args: any[]) => void; onQueueIssueCountUpdated: (...args: any[]...' is not assignable to type 'IntrinsicAttributes & Omit<Props, "intl"> & { forwardedRef?: Ref<any> | undefined; } & { children?: ReactNode; }'.
			onQueueRenderFailure={getErrorCallback(pageContext.projectKey)(replace)}
			initialState={getInitialState(omit(pageContext, 'isSiteAdmin'))}
			locale={pageContext.locale}
			isQueueVisible
		/>
	);
};

export default withInjectedProps(DefaultQueues, {
	NoQueuesView: NoQueuesViewDI,
	AgentView: AgentViewDI,
});
